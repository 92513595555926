import { LeverJob } from "types/leverJob"

interface UseJobFiltererProps {
  defaultTeamOption: string
  defaultLocationOption: string
  defaultWorkTypeOption: string
  teamFilter: string
  locationFilter: string
  workTypeFilter: string
  jobs: LeverJob[]
}

export const getFilteredJobs = ({
  defaultTeamOption,
  defaultLocationOption,
  defaultWorkTypeOption,
  teamFilter,
  locationFilter,
  workTypeFilter,
  jobs,
}: UseJobFiltererProps): LeverJob[] => {
  if (
    teamFilter === defaultTeamOption &&
    locationFilter === defaultLocationOption &&
    workTypeFilter === defaultWorkTypeOption
  ) {
    return jobs
  }

  let filteredJobs = jobs

  if (teamFilter !== defaultTeamOption) {
    filteredJobs = filteredJobs.filter((job) => job.categories.team === teamFilter)
  }

  if (locationFilter !== defaultLocationOption) {
    filteredJobs = filteredJobs.filter((job) => job.categories.location === locationFilter)
  }

  if (workTypeFilter !== defaultWorkTypeOption) {
    filteredJobs = filteredJobs.filter((job) => job.categories.commitment === workTypeFilter)
  }

  return filteredJobs
}
