import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { CareersView } from "components/views/CareersView/CareersView"
import { graphql } from "gatsby"
import { ContentfulImage } from "types/contentful"
import { LeverJob } from "types/leverJob"

interface CareersPageProps {
  location: WindowLocation
  data: {
    allLever: {
      nodes: LeverJob[]
    }
    hero: ContentfulImage
    metaImage?: ContentfulImage
  }
}

const CareersPage = ({ location, data }: CareersPageProps): JSX.Element => {
  const { allLever, hero, metaImage } = data

  const departments = [
    ...new Set(
      allLever.nodes.map((node) => {
        return node.categories.department
      }),
    ),
  ]

  const locations = [
    ...new Set(
      allLever.nodes.map((node) => {
        return node.categories.location
      }),
    ),
  ]

  const workTypes = [
    ...new Set(
      allLever.nodes.map((node) => {
        return node.categories.commitment
      }),
    ),
  ]

  return (
    <>
      <Seo
        description="Join the frontline of tech. We're looking for ambitious, creative, kind-hearted people to make freight autonomous, electric, and sustainable. See openings."
        image={metaImage?.media.gatsbyImageData}
        pathname={location.pathname}
        title="Welcome to the Frontline"
      />
      <CareersView
        departments={departments}
        jobs={allLever.nodes}
        locations={locations}
        workTypes={workTypes}
        hero={hero}
      />
    </>
  )
}

export const query = graphql`
  query {
    allLever(sort: { fields: createdAt, order: DESC }) {
      nodes {
        lever_id
        text
        createdAt
        description
        applyUrl
        additional
        hostedUrl
        categories {
          commitment
          department
          location
          team
        }
      }
    }
    hero: contentfulImage(contentful_id: { eq: "3otztzX6ebdstpcRk2CKMg" }) {
      media {
        gatsbyImageData(aspectRatio: 1.7658536585)
      }
    }
    metaImage: contentfulImage(contentful_id: { eq: "6uVZnNnJSrqKlD7mYKGQSt" }) {
      ...ContentfulImage
    }
  }
`

export default CareersPage
