import { Box, Caption, Select, Table, Tbody, Td, Th, Thead, Tr, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled/macro"
import { Col, ContentWrapper, Row } from "components/shared/styles/Layout"
import { Layout } from "components/ui/Layout/Layout"
import { PageHero } from "components/ui/PageHero/PageHero"
import { navigate } from "gatsby"
import { cvars } from "lib/theming/breakpoint"
import { Paragraph, Title1, Title2 } from "lib/ui/Typography/Typography"
import { useState } from "react"
import { ContentfulImage } from "types/contentful"
import { LeverJob } from "types/leverJob"
import { getFilteredJobs } from "./utils/getFilteredJobs"

interface CareersViewProps {
  departments: string[]
  workTypes: string[]
  hero: ContentfulImage
  jobs: LeverJob[]
  locations: string[]
}

export const CareersView = ({
  departments,
  workTypes,
  hero,
  jobs,
  locations,
}: CareersViewProps): JSX.Element => {
  const [teamFilter, setTeamFilter] = useState(DEFAULT_TEAM_OPTION)
  const [locationFilter, setLocationFilter] = useState(DEFAULT_LOCATION_OPTION)
  const [workTypeFilter, setWorkTypeFilter] = useState(DEFAULT_WORKTYPE_OPTION)

  const filteredJobs: Job[] = getFilteredJobs({
    defaultTeamOption: DEFAULT_TEAM_OPTION,
    defaultLocationOption: DEFAULT_LOCATION_OPTION,
    defaultWorkTypeOption: DEFAULT_WORKTYPE_OPTION,
    teamFilter,
    locationFilter,
    workTypeFilter,
    jobs,
  }).map((job) => ({
    commitment: job.categories.commitment,
    department: job.categories.department,
    team: job.categories.team,
    id: job.lever_id,
    location: job.categories.location,
    title: job.text,
  }))

  const filteredDepartments: string[] = [
    ...new Set(
      filteredJobs.map((job) => {
        return job.department
      }),
    ),
  ]

  const teamsByDepartment = (department: string): string[] => [
    ...new Set(
      jobs
        .filter((job) => job.categories.department === department)
        .map((job) => job.categories.team),
    ),
  ]

  const filteredJobsByDepartment = (department: string): Job[] => {
    return filteredJobs.filter((job) => job.department === department)
  }

  const handleRowClick = (job: Job): void => {
    navigate(`/careers/${job.id}`)
  }

  return (
    <>
      <Box background="primaryInverted" color="primaryInverted" padding={1}>
        <Caption style={{ textAlign: "center" }}>
          Be careful of individuals trying to impersonate recruiters. Einride never asks candidates
          to pay for anything during a hiring process. If you have been asked to do so, please{" "}
          <a href="https://einride.tech/contact">
            <u>contact us</u>
          </a>{" "}
          immediately.
        </Caption>
      </Box>
      <Layout>
        <PageHero image={hero.media.gatsbyImageData}>
          <Row>
            <Col width={1 / 2}>
              <Title1>Welcome to the frontline</Title1>
              <Paragraph>
                Einride is growing fast and always on the lookout for ambitious, creative and
                kind-hearted people with relevant expertise to join the team.
              </Paragraph>
            </Col>
            <Col width={1 / 2} />
          </Row>
        </PageHero>
        <ContentWrapper>
          <Title1 as="h2">Roles</Title1>
          <VerticalSpacing size="sm" />
          <DropDownFlex>
            <DropdownWrapper>
              <Select
                defaultValue={DEFAULT_TEAM_OPTION}
                label="Team"
                onChange={(e) => setTeamFilter(e.target.value)}
              >
                <option value={DEFAULT_TEAM_OPTION}>{DEFAULT_TEAM_OPTION}</option>
                {departments.map((department: string) => (
                  <optgroup key={department} label={department}>
                    {teamsByDepartment(department).map((team) => (
                      <option value={team} key={team}>
                        {team}
                      </option>
                    ))}
                    ,
                  </optgroup>
                ))}
              </Select>
            </DropdownWrapper>
            <DropdownWrapper>
              <Select
                defaultValue={DEFAULT_WORKTYPE_OPTION}
                label="Work type"
                onChange={(e) => setWorkTypeFilter(e.target.value)}
              >
                <option value={DEFAULT_WORKTYPE_OPTION}>{DEFAULT_WORKTYPE_OPTION}</option>
                {workTypes.map((workType) => (
                  <option value={workType} key={workType}>
                    {workType}
                  </option>
                ))}
              </Select>
            </DropdownWrapper>
            <DropdownWrapper>
              <Select
                defaultValue={DEFAULT_LOCATION_OPTION}
                label="Location"
                onChange={(e) => setLocationFilter(e.target.value)}
              >
                <option value={DEFAULT_LOCATION_OPTION}>{DEFAULT_LOCATION_OPTION}</option>
                {locations.map((location) => (
                  <option value={location} key={location}>
                    {location}
                  </option>
                ))}
              </Select>
            </DropdownWrapper>
          </DropDownFlex>
          <VerticalSpacing size="md" />
          {filteredDepartments.length ? (
            filteredDepartments.map((department) => (
              <TableWrapper key={department}>
                <Title2>{department}</Title2>
                <Table>
                  <Thead>
                    <Tr>
                      <StyledTh scope="col">Role</StyledTh>
                      <StyledTh scope="col">Team</StyledTh>
                      <StyledTh scope="col">Work type</StyledTh>
                      <StyledTh scope="col">Location</StyledTh>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredJobsByDepartment(department).map((job) => (
                      <StyledTr key={job.id} onClick={() => handleRowClick(job)}>
                        <Td>{job.title}</Td>
                        <Td>{job.team}</Td>
                        <Td>{job.commitment}</Td>
                        <Td>{job.location}</Td>
                      </StyledTr>
                    ))}
                  </Tbody>
                </Table>
              </TableWrapper>
            ))
          ) : (
            <Paragraph>There are currently no open positions for the applied filters.</Paragraph>
          )}
          <VerticalSpacing size="xl" />
        </ContentWrapper>
        <VerticalSpacing size="xl" />
      </Layout>
    </>
  )
}

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => 8 * theme.spacer}px;
  overflow-x: auto;
`

const StyledTh = styled(Th)`
  color: ${({ theme }) => theme.colors.content.secondary};
`

const DropDownFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(${cvars.gridGutter} / 2 * -1);
`

const DropdownWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 0 calc(${cvars.gridGutter} / 2);

  @media screen and (min-width: 800px) {
    margin-bottom: 0;
    width: 33%;
  }

  @media screen and (min-width: 1200px) {
    width: 25%;
  }
`

const StyledTr = styled(Tr)`
  cursor: pointer;
`

const DEFAULT_TEAM_OPTION = "All teams"
const DEFAULT_LOCATION_OPTION = "All locations"
const DEFAULT_WORKTYPE_OPTION = "All work types"

interface Job {
  commitment: string
  department: string
  id: string
  location: string
  title: string
  team: string
}
